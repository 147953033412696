import React from 'react';
import PropTypes from 'prop-types';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

import styles from './LoadMoreButton.scss';

const LoadMoreButton = ({ fetchMore, hasMore, loading, start, searchType }) => {
  const button = loading ? (
    <Spinner ariaTextActive="Loading more products" isActive />
  ) : (
    <Button
      aria-label="Load more"
      data-actiontype="load"
      data-shortdescription="Load more products"
      label="Load more..."
      theme="secondary"
      onClick={() => {
        if (hasMore) fetchMore({ start, searchType });
      }}
    />
  );

  return hasMore ? <div className={styles.loadMoreWrapper}>{button}</div> : null;
};

LoadMoreButton.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  start: PropTypes.number,
  searchType: PropTypes.string,
};

LoadMoreButton.displayName = 'LoadMoreButton';

export default LoadMoreButton;
