import { UPDATESEARCH } from 'redux/modules/search/actions/types';

import { getAppliedProductFacets } from 'redux/modules/search-and-browse/selectors';

import initialState from 'redux/modules/search/reducer/initial-state';

// TODO: Try to rethink approach to keep criteria and queryParams sync
export const updateSearchQueryParams = () => (dispatch, getState) => {
  const params = {
    category: getState().search.queryParams.category,
    filterTags: getAppliedProductFacets(getState()),
    searchTags: getState().searchAndBrowse.criteria.searchTags,
    searchTerm: '',
    sortBy: getState().search.queryParams.sortBy || initialState.queryParams.sortBy,
  };

  dispatch({
    params,
    type: UPDATESEARCH,
  });
};
