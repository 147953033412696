import { apiCallback } from 'api';

const definition = {
  service: 'content',
  endpoint: 'shop/featured/${rootCategory}/${aisleRef}',
  query: {
    clientType: 'WEB_APP',
  },
  verbs: ['put'],
  defaultVersion: 2,
};

export default {
  put: apiCallback(definition, 'put'),
};
