import { apiCallback } from 'api';
import { trackGraphPersonalisationHeaderAndRecommendations } from 'api/utils/personalisation-header';
import { getErrorFromFailures } from 'api/getErrorFromFailures';
import plp from './plp.graphql';
import ProductFragment from './product-fragment.graphql';
import ProductPodFragment from './product-pod-fragment.graphql';

const graphDef = (graphQuery, experience) => {
  const query = () => ProductFragment + ProductPodFragment + graphQuery;
  const variables = ({
    start,
    size,
    sortBy,
    trolleyId,
    recommendationsSize,
    withFallback,
    category,
    customerId,
    filterTags,
  }) => ({
    start,
    size,
    sortBy,
    trolleyId,
    recommendationsSize,
    withRecommendations: !!recommendationsSize,
    withFallback,
    category,
    customerId,
    filterTags,
  });

  return {
    graph: true,
    query,
    variables,
    ...(experience && { headers: { experience } }),
    queryString: { tag: 'browse' },
  };
};

const getErrorFromResponse = ({ data }) =>
  getErrorFromFailures(data?.getProductListPage?.productGridData?.failures);

export default {
  post: ({ experience }) =>
    apiCallback(
      graphDef(plp, experience),
      'post',
      trackGraphPersonalisationHeaderAndRecommendations('getProductListPage'),
      getErrorFromResponse,
    ),
};
