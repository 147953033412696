import { NEXTLOADED } from 'redux/modules/search-and-browse/actions/types';
import { fetchComponentsAndProducts } from 'redux/modules/search-and-browse/actions/fetch-components-and-products';
import { fetchComponentsAndProducts as fetchComponentsAndProductsWithRecommendations } from 'redux/modules/search-and-browse/actions/fetch-components-and-products-with-recommendations';

export const fetchMoreComponentsAndProducts = ({ start, searchType } = {}) => {
  const fetch =
    searchType === 'Browse'
      ? fetchComponentsAndProductsWithRecommendations
      : fetchComponentsAndProducts;

  return fetch({ start, successType: NEXTLOADED });
};
