export const getErrorFromFailures = failures => {
  if (failures?.length) {
    const { field, message, type } = failures[0];

    const error = new Error(message);
    error.field = field;
    error.status = parseInt(type, 10);

    return error;
  }

  return null;
};
