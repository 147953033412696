import customaisle from 'api/definitions/customaisle';
import offers from 'api/definitions/offers';
import offersgroup from 'api/definitions/offersgroup';
import offerssearch from 'api/definitions/offerssearch';
import products from 'api/definitions/products';
import search from 'api/definitions/search';

import { FAILED, LOADED, LOADING } from 'redux/modules/search-and-browse/actions/types';

import { redirectFromSearchResults } from 'utils/general';
import { setRequestProperty } from 'redux/modules/search-and-browse/actions/utils/set-request-property';

import { getGridItems } from 'redux/modules/search-and-browse/selectors';

import { updateSearchQueryParams } from 'redux/modules/search/actions/update-search-query-params';
import { GET_CMS_PAGE_NOT_FOUND } from 'redux/modules/cms-page/actions/types';

const notFoundStatuses = [400, 404];
const api = { customaisle, offers, offersgroup, offerssearch, products, search };

export const fetchComponentsAndProducts =
  ({
    request: req = undefined,
    start = 0,
    successType = LOADED,
    refinement = undefined,
    categoryId,
    categoryLevel,
  } = {}) =>
  (dispatch, getState) => {
    const { noRedirects = false } = req || {};
    const state = getState();
    const {
      offers: { activeOffer: isOffer = false } = {},
      searchAndBrowse: { displayedProducts, request: searchAndBrowseRequest } = {},
    } = state;
    let request = req || searchAndBrowseRequest;

    if (start !== 0) request = setRequestProperty(request, 'start', start);

    // If products have been invalidated load a fresh page,
    // or the previously loaded total, whichever is greater
    if (displayedProducts !== 0) {
      if (getGridItems(state).length < displayedProducts) {
        request = setRequestProperty(
          request,
          'size',
          Math.min(96, Math.max(48, displayedProducts)),
        );
      }
    }

    if (typeof categoryId === 'number')
      request = setRequestProperty(request, 'category', categoryId);

    if (typeof categoryLevel === 'number')
      request = setRequestProperty(request, 'categoryLevel', categoryLevel);

    const { api: requestApi, body, method, properties, schema, transform } = request;
    const canHaveBody = method !== 'get';

    return dispatch({
      types: [LOADING, successType, FAILED],
      noRedirects,
      request,
      start,
      isOffer,
      apiCall: api[requestApi][method]({
        ...(canHaveBody && { body }),
        ...properties,
        iHandleStatusCodes: notFoundStatuses,
      }),
      schema,
      transform,
      refinement,
    })
      .then((result = {}) => {
        const { name } = request;
        const { searchAndBrowse: { criteria, redirectUrl } = {} } = getState();

        if (redirectUrl) {
          redirectFromSearchResults(redirectUrl);
        }

        if (criteria && name === 'Search') {
          dispatch(updateSearchQueryParams());
        }

        return result;
      })
      .catch(error => {
        if (notFoundStatuses.includes(error.status)) {
          return dispatch({ type: GET_CMS_PAGE_NOT_FOUND });
        }
        throw error;
      });
  };
