import { apiCallback } from 'api';

const definition = {
  service: 'custsearch',
  endpoint: 'search/${customerId}',
  query: {
    orderId: '${orderId}',
    pmsPromotion: '${pmsPromotion}',
    promotion_id: '${promotionId}',
    size: '${size}',
    start: '${start}',
    categoryLevel: '${categoryLevel}',
    category: '${category}',
  },
  verbs: ['get'],
  defaultVersion: 3,
};

export default {
  get: apiCallback(definition, 'get'),
};
