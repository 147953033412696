import has from 'lodash/has';
import immutable from 'dot-prop-immutable';

export const setRequestProperty = (request, property, value) => {
  if (request.method === 'get') {
    return immutable.set(request, `properties.${property}`, value);
  }
  const queryPath = has(request, 'body.customerSearchRequest.queryParams')
    ? 'body.customerSearchRequest.queryParams'
    : 'body.queryParams';
  return immutable.set(request, `${queryPath}.${property}`, value);
};
